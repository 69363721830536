import { markRead, notificationActionsMap } from '@utils/StreamNotificationUtils';
import { attachTrackingAnalytics } from '@services/SegmentService';
import {
  BID_REQUEST_ID,
  BOUNCED_USER_ID,
  CLICKED_NOTIFICATION,
  DISMISSED_SPECIFIC_NOTIFICATION,
  NOTIFICATION_TYPE
} from '@utils/analytics_constants';
import { alertMessage } from '@utils/common';
import { updateCompanyInvite } from '@api/CompanyUserInviteAcceptanceApi';
import { updateBidAcceptance } from '@api/BidAcceptanceApi';
import { Notification, actionProps } from './types';

export const idRequestMapAndNotificationMap = (notifyList: any[]) => {
  const requestTitleMapById: {[key: string]: any} = {};
  const notificationsMapByRequest: {[key: string]: any} = {};

  notifyList.forEach(notify => {
    if (notify.actions_object && notify.additional_info && notify.additional_info.bid_request_id) {
      const requestId = notify.additional_info.bid_request_id;

      if (!requestTitleMapById[requestId]) {
        requestTitleMapById[requestId] = notify.additional_info.bid_request_name;
        notificationsMapByRequest[requestId] = [];
      }

      notificationsMapByRequest[requestId].push(notify);
    }
  });
  return [requestTitleMapById, notificationsMapByRequest];
};

// Based on components/layout/Header/NotificationsDisplay/NotificationItemsList => NotificationItem => onAccept
export const handleAcceptAction = ({
  feed,
  item,
  user,
  allList = [],
  setAllList = () => {},
  router,
  flags,
}: actionProps) => {
  markRead(feed, item.activity_id);

  // Added safety because of delicate nature of child to child to child
  if (item.actions_object && item.actions_object.affirm && item.additional_info) {
    const affirmAction = notificationActionsMap[item.actions_object.affirm.action];

    affirmAction(item.additional_info, router, flags);

    const additionalInfoAndAction = item.additional_info && item.action_name;

    // Analytics Tracking (Move out to function)
    if (additionalInfoAndAction && item.additional_info.bid_request_id) {
      attachTrackingAnalytics(
        user,
        CLICKED_NOTIFICATION,
        {
          [NOTIFICATION_TYPE]: item.action_name,
          [BID_REQUEST_ID]: item.additional_info.bid_request_id
        }
      );
    }
    else if (additionalInfoAndAction && item.additional_info.user_id) {
      attachTrackingAnalytics(
        user,
        CLICKED_NOTIFICATION,
        {
          [NOTIFICATION_TYPE]: item.action_name,
          [BOUNCED_USER_ID]: item.additional_info.user_id
        }
      );
    }
  }

  // setShowPopover(false); // TODO: how do we close a v5 popover?
  setAllList(allList.filter((notify: Notification) => (item.activity_id !== notify.activity_id)));
};

// Based on components/layout/Header/NotificationsDisplay/NotificationItemsList => NotificationItem => onDecline
export const handleDeclineAction = ({
  feed,
  item,
  user,
  allList = [],
  setAllList = () => {},
}: actionProps) => {
  markRead(feed, item.activity_id);

  if (item.additional_info && item.action_name) {
    if (item.additional_info.bid_request_id) {
      attachTrackingAnalytics(
        user,
        DISMISSED_SPECIFIC_NOTIFICATION,
        {
          [NOTIFICATION_TYPE]: item.action_name,
          [BID_REQUEST_ID]: item.additional_info.bid_request_id
        }
      );
    }
    else if (item.additional_info.user_id) {
      attachTrackingAnalytics(
        user,
        DISMISSED_SPECIFIC_NOTIFICATION,
        {
          [NOTIFICATION_TYPE]: item.action_name,
          [BOUNCED_USER_ID]: item.additional_info.user_id
        }
      );
    }
  }

  setAllList(allList.filter((notify: Notification) => (item.activity_id !== notify.activity_id)));
};

// Based on components/layout/Header/NotificationsDisplay/NotificationsDisplay => handleAction
export const handleUpdateCompanyInviteAction = ({
  item,
  answer = '',
  generalContext
}: actionProps) => {
  const isAccepted = answer === 'accepted';

  if (!item?.activity_id) return;

  // There's 1 notification viewable, it's about to accepted or rejected. Doing this here, avoids worrying about
  // the timing of state changes in the Promise below
  // if (totalNotificationsCount === 1) setShowPopover(false); // TODO: how do we close a v5 popover?

  updateCompanyInvite(item?.activity_id as number, answer).then((response: any) => {
    if (response.success) {
      const confirmationMessage = isAccepted ? 'Invitation Accepted' : 'Invitation Rejected';

      alertMessage(confirmationMessage);

      // Get bid acceptances first, so that if a user accepts a bid request, and the company invite is
      // auto-accepted, notifications for accepting company invite will go away
      const { getAndSetBidAcceptances, getAndSetCompanyInvites, incrementRequestChangeCount } = generalContext;

      getAndSetBidAcceptances().then(getAndSetCompanyInvites);
      incrementRequestChangeCount();
    } else {
      alertMessage(response.errors[0], 'error');
    }
  });
};

// Based on components/layout/Header/NotificationsDisplay/NotificationsDisplay => handleAction
export const handleUpdateRequestInviteAction = ({
  item,
  answer = '',
  generalContext,
}: actionProps) => {
  const isAccepted = answer === 'accepted';

  if (!item?.activity_id) return;

  // There's 1 notification viewable, it's about to accepted or rejected. Doing this here, avoids worrying about
  // the timing of state changes in the Promise below
  // if (totalNotificationsCount === 1) setShowPopover(false); // TODO: how do we close a v5 popover?

  updateBidAcceptance(item?.activity_id as number, answer).then((response: any) => {
    if (response.success) {
      const confirmationMessage = isAccepted ? 'Invitation Accepted' : 'Invitation Rejected';

      alertMessage(confirmationMessage);

      // Make sure the request is removed from view on any page you're declining the
      if (!isAccepted) {
        const { unauthorizedRequestIds } = generalContext;

        unauthorizedRequestIds.push(response.bid_acceptance.bid_request_id);
        generalContext.setGeneralProperty({ unauthorizedRequestIds });
      }

      // Get bid acceptances first, so that if a user accepts a bid request, and the company invite is
      // auto-accepted, notifications for accepting company invite will go away
      const { getAndSetBidAcceptances, getAndSetCompanyInvites, incrementRequestChangeCount } = generalContext;

      getAndSetBidAcceptances().then(getAndSetCompanyInvites);
      incrementRequestChangeCount();
    } else {
      alertMessage(response.errors[0], 'error');
    }
  });
};

export const determineAction = (key: string): Function => {
  if (key === 'affirm') {
    return handleAcceptAction;
  } if (key === 'cancel') {
    return handleDeclineAction;
  } if (['accepted_company_invite', 'rejected_company_invite'].includes(key)) {
    return handleUpdateCompanyInviteAction;
  } if (['accepted_request_invite', 'rejected_request_invite'].includes(key)) {
    return handleUpdateRequestInviteAction;
  }

  return () => {}; // Unknown action
};
